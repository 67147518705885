<template>
  <div class="stories template-1">
    <header class="page-header container mx-sm">
      <h1>{{ announcementType }} {{ translations.tcAnnouncement }}</h1>
      <BannerAid :bannerLink="`announcement-detail|` + userSelectedAnnouncementKey" />
    </header>
    <div class="page-body container mx-sm">
      <div v-if="alpha.msg_title !== ''" class="story">
        <h2 class="title-1">{{ alpha.msg_title }}</h2>
        <h4 class="title-2">
          {{ getTranslatedDateString(alpha.fromdate) }} |
          <span style="color: #888">{{ translations.tcExpires }}: {{ getTranslatedDateString(alpha.expdate) }}</span>
          <br />
          {{ translations.tcBy }} {{ alpha.author }}
        </h4>
        <p class="rendered-message" v-html="alphaMessageRendered"></p>
        <div class="inline-flex">
          <button class="btn btn-primary mr-4" @click="handleSeeAllClick">
            {{ translations.tcSeeAllAnnouncements }}
          </button>
          <div v-if="!!alpha.attachments && alpha.attachments.length > 0" class="attachment-list"
            style="display: inline-grid">
            <h4>{{ translations.tcAttachments }}</h4>
            <div v-for="(att, idx) in alpha.attachments" :key="idx" style="width: 400px">
              <iPaperclip />&nbsp;
              <a :href="att.att_file_name_path" :download="getAttachmentName(att.att_file_name_path)" target="_blank">{{
                  getAttachmentName(att.att_file_name_path)
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer"></div>
    <div class="page-extra">
      <div class="container bg-primary more-testimonials">
        <h2 class="text-white">{{ translations.tcMoreAnnouncements }}</h2>
        <section class="ann-cards">
          <div class="row">
            <div v-for="(ann, index) in beta_gamma_delta" :key="index" class="col-4 mb-4 col-100-sd">
              <AnnCard :obj="ann" @view_more="handleReadMoreClick($event)" :i18n="translations.components"></AnnCard>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnCard from '@/components/ann-card/AnnCard.vue'
import BannerAid from '@/components/BannerAid.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import { messagePresentationMixin } from '@/mixins/messagePresentationMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'announcement-detail',
  mixins: [translationMixin, messagePresentationMixin],
  data() {
    return {
      announcementType: '',
      summaryToggle: false,
      beta_gamma_delta: [],
      empty_announcement: {
        cat: '',
        category: '',
        msg_key: '',
        msg_include_elected_flag: false,
        author: '',
        ordinal: 0,
        fromdate: '',
        expdate: '',
        expdate2: '',
        sortdate: '',
        msg_title: '',
        msg_message: '',
        passed: true,
        attachments: [],
        ttl: '',
        preview: '',
      },
      stateOrCountry: 'State',
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAnnouncementKey: 'user/setSelectedAnnouncementKey',
    }),
    getAttachmentName(path) {
      const pathPieces = path.split('/')
      return pathPieces[pathPieces.length - 1]
    },
    getTranslatedDateString(givenDate) {
      if (!givenDate) return false

      const actualDate = new Date(givenDate)
      return actualDate.toLocaleString(this.prefCulture, { year: 'numeric', month: 'long', day: 'numeric' })
    },
    async handleEditClick(msgid) { },
    async handleSeeAllClick() {
      await this.setSelectedAnnouncementKey(null)
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleReadMoreClick(evt) {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.setSelectedAnnouncementKey(evt.msg_key),
          await this.specificAnnouncement(evt.msg_key),
          await (this.beta_gamma_delta = this.moreAnnouncements(evt.msg_key)),
        ]).then(() => {
          this.summaryToggle = false
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    setAnnouncementType() {
      this.stateOrCountry = this.userLogin.membertype.toLowerCase() === 'usa' ? 'State' : 'Country'
      if (this.alpha.cat.toLowerCase() !== 'state' || this.stateOrCountry === 'State') {
        this.announcementType = this.alpha.cat
      } else {
        this.announcementType = 'Country'
      }
    },
  },
  computed: {
    ...mapGetters({
      announcements: 'announcements/announcements',
      moreAnnouncements: 'announcements/moreAnnouncements',
      prefCulture: 'user/userPreferredCulture',
      specificAnnouncement: 'announcements/specificAnnouncement',
      storySummary: 'successStory/storySummary',
      userLogin: 'user/userLogin',
      userSelectedAnnouncementKey: 'user/userSelectedAnnouncementKey',
    }),
    alpha() {
      let spec = this.specificAnnouncement(this.userSelectedAnnouncementKey)
      return !!spec ? spec : this.empty_announcement
    },
    alphaMessageRendered() {
      return this.adjustMessage(this.alpha.msg_message)
    },
    beta() {
      return this.beta_gamma_delta[0] || this.empty_announcement
    },
    gamma() {
      return this.beta_gamma_delta[1] || this.empty_announcement
    },
    delta() {
      return this.beta_gamma_delta[2] || this.empty_announcement
    },
  },
  async created() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.setAnnouncementType(),
        this.getComponentTranslations('ann-card'),
        await (this.beta_gamma_delta = this.moreAnnouncements(this.userSelectedAnnouncementKey)),
      ]).then((results) => {
        const componentTranslations = results[3]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    AnnCard: AnnCard,
    BannerAid: BannerAid,
    iPaperclip: iPaperclip,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.rendered-message {
  white-space: pre-wrap;
}

.stories {
  .title-1 {
    margin-bottom: 0;
    color: #000;
    font-family: $DIN;
    font-size: 40px;
    letter-spacing: 0.03em;
    line-height: 44px;
    text-transform: uppercase;
  }

  .title-2 {
    margin-bottom: 8px;
    color: $teal-700;
    font-size: 30px;
    letter-spacing: 0.03em;
    line-height: 44px;
    text-transform: none;
  }

  p {
    margin-bottom: 36px;
    font-size: 18px;
    font-family: $lora;
    letter-spacing: 0.03em;
    line-height: 36px;
  }
}

.page-extra {
  @include breakpoint(sm) {
    padding: 0 15px;
  }

  .more-testimonials {
    padding: 50px 60px 60px;

    @include breakpoint(sm) {
      padding: 30px 68px 68px;
    }

    >.d-flex {
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }

      >div {
        margin-right: 35px;
        padding: 21px 33px 30px;

        @include breakpoint(sm) {
          width: 100%;
          margin-bottom: 24px;
          margin-right: 0;
          padding: 34px 50px;
        }

        &:last-of-type {
          margin-right: 0;

          @include breakpoint(sm) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 30px;
    font-family: $DIN;
    font-size: 40px;
    letter-spacing: 1.33px;
    line-height: 44px;
  }

  h3 {
    margin-bottom: 2px;
    color: #000;
    font-family: $DIN;
    font-size: 26px;
    letter-spacing: 0.78px;
    line-height: 44px;
  }

  .meta-date {
    margin-bottom: 11px;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 36px;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 43px;
    font-family: $lora;
    font-size: 16px;
    letter-spacing: 0.64px;
    line-height: 24px;
  }
}
</style>
